// ///////////////////////////////////////////////////////////////////////////
// JS for the quickbook (aka booking bar) ui
// ///////////////////////////////////////////////////////////////////////////

(function() {

    const host = document.getElementById("booking-api-endpoint").value;
    const urls = {
        arenas: () => { return `${host}/api/booking/locations`; },
        events: locationId => { return `${host}/api/booking/locations/${locationId}/events`; },
        timeSlots: eventId => { return `${host}/api/booking/events/${eventId}/timeslots`; },
        landing: () => { return `${host}/booking/events/landing`; }
    }

    const model = (function() {

        const model = {};

        function stepsKeysToArray() {
            return Object.keys(model.steps);
        }

        model.steps = {
            where: {
                id: "location-select",
                ele: null,
                value: 0,
                onChange: onWhereChanged,
                get: getLocations
            },
            when: {
                id: "date-select",
                ele: null,
                value: "",
                onChange: onWhenChanged,
                get: getDates
            },
            event: {
                id: "event-select",
                ele: null,
                value: 0,
                onChange: onEventChanged,
                get: getEvents
            },
            time: {
                id: "time-select",
                ele: null,
                value: "",
                onChange: onTimeChanged,
                get: getTimeSlots
            },
            guests: {
                id: "guest-select",
                ele: null,
                value: 0,
                onChange: onGuestsChanged,
                get: getGuests
            }
        };

        model.stepAfter = key => {
            const keys = stepsKeysToArray();
            const pos = keys.indexOf(key);
            const newKey = keys[pos + 1];
            if (newKey === null || newKey === undefined) return null;
            return {
                key: newKey,
                step: model.steps[newKey]
            };
        }

        model.getStep = (key) => {
            return model.steps[key];
        }

        model.init = () => {
            for (let key of stepsKeysToArray()) {
                const inst = model.steps[key];
                inst.ele = document.getElementById(inst.id);
                inst.ele.addEventListener("change", inst.onChange);
            }
        };

        return model;
    })();

    function updateBarState(changedKey) {
        document.getElementById("quickbook-book-now").setAttribute("disabled", true);

        const keys = Object.keys(model.steps);
        let currentIndex = keys.indexOf(changedKey);

        currentIndex = currentIndex === -1 ? null : currentIndex;

        for (let i =  (currentIndex || 0) + 1; i < keys.length; i ++) {
            const keyToReset = keys[i];
            const select = model.steps[keyToReset];

            const ele = document.getElementById(select.id);
            ele.setAttribute("disabled", "disabled");
            clearSelect(ele);
        }

        for (let i = 0; i < (currentIndex || 0) + 1; i++) {
            const key = keys[i];
            const select = model.steps[key];

            const ele = document.getElementById(select.id);
            ele.removeAttribute("disabled");
        }
    }
    function clearSelect(select) {
        /// <summary>
        /// Clears the select box but keeps the first element (placeholder)
        /// </summary>
        /// <param name="select"></param>

        const placeHolder = select.firstElementChild;
        while (select.firstElementChild) {
            $(select.firstElementChild).remove();
        }
        select.appendChild(placeHolder);
    }
    function clearModelAfter(key) {
        const keys = Object.keys(model.steps);
        const pos = keys.indexOf(key) + 1;

        for (let i = pos; i < keys.length; i++) {
            model.steps[keys[i]].value = null;
        }
    }

    function getLocations() {
        jQuery.get(urls.arenas(), data => {
            const ele = model.getStep("where").ele;
            clearSelect(ele);
            updateBarState("where");

            for (let location of data) {
                const locEle = document.createElement("option");
                locEle.value = location.id;
                locEle.text = location.name;
                ele.appendChild(locEle);
            }
        });
    }
    function getDates() {

        const locationId = model.getStep("where").value;

        jQuery.get(urls.events(locationId), data => {
            const curStep = model.getStep("when");
            
            if (data.length < 1) {
                alert("No event dates found for this Arena");
                return;
            }

            clearModelAfter("when");
            updateBarState("when");

            const dates = data.reduce((rv, x) => {
                const date = moment(x.startDateTime).format("LL");
                rv = rv || [];
                if (rv.indexOf(x) < 0) {
                    rv.push(date);
                }
                return rv;
            },[]);

            clearSelect(curStep.ele);
            for (let date of dates) {
                const ele = document.createElement("option");
                ele.value = date;
                ele.text = date;
                curStep.ele.appendChild(ele);
            }
        });
    }
    function getEvents() {
        const locationId = model.getStep("where").value;

        jQuery.get(urls.events(locationId), data => {
            const curStep = model.getStep("event");
            
            if (data.length < 1) {
                alert("No events found for this Arena and this Date");
                return;
            }

            clearModelAfter("event");
            updateBarState("event");

            clearSelect(curStep.ele);
            for (let event of data) {
                const ele = document.createElement("option");
                ele.value = event.id;
                ele.text = event.name;
                curStep.ele.appendChild(ele);
            }
        });
    }
    function getTimeSlots() {
        const eventId = model.getStep("event").value;

        jQuery.get(urls.timeSlots(eventId), data => {
            const curStep = model.getStep("time");
            
            if (data.length < 1) {
                alert("No time slots found for this event");
                return;
            }

            clearModelAfter("time");
            updateBarState("time");

            clearSelect(curStep.ele);
            for (let timeSlot of data) {

                const start = moment(timeSlot.startDateTime).format("LT");
                const end = moment(timeSlot.endDateTime).format("LT");

                const ele = document.createElement("option");
                ele.value = timeSlot.id;
                ele.text = `${start} - ${end}`;
                curStep.ele.appendChild(ele);
            }
        });
    }
    function getGuests() {
        const eventId = model.getStep("event").value;
        const timeStep = model.getStep("time");

        jQuery.get(urls.timeSlots(eventId), data => {
            const curStep = model.getStep("guests");
            
            if (data.length < 1) {
                alert("No time slots found for this event");
                return;
            }

            clearModelAfter("guests");
            updateBarState("guests");

            clearSelect(curStep.ele);
            const slot = data.filter(x => x.id.toString() === timeStep.value)[0];

            for (let i = 0; i < slot.freeSpaces; i++) {
                const ele = document.createElement("option");
                ele.value = i + 1;
                ele.text = `${i + 1} guest`;
                if (i !== 0) {
                    ele.text += "s";
                }
                curStep.ele.appendChild(ele);
            }
        });
    }

    // Event Handlers
    function onWhereChanged(event) {
        const key = "where";
        model.getStep(key).value = event.target.value;
        clearModelAfter(key);
        updateBarState(key);
        model.stepAfter(key).step.get();
    }
    function onWhenChanged(event) {
        const key = "when";
        model.getStep(key).value = event.target.value;
        clearModelAfter(key);
        updateBarState(key);
        model.stepAfter(key).step.get();
    }
    function onEventChanged(event) {
        const key = "event";
        model.getStep(key).value = event.target.value;
        clearModelAfter(key);
        updateBarState(key);
        model.stepAfter(key).step.get();
    }
    function onTimeChanged(event) {
        const key = "time";
        model.getStep(key).value = event.target.value;
        clearModelAfter(key);
        updateBarState(key);
        model.stepAfter(key).step.get();
    }
    function onGuestsChanged(event) {
        const form = document.getElementById("event-booking-bar-form");
        form.setAttribute("action", urls.landing());

        const bookNowButton = document.getElementById("quickbook-book-now");
        if (event.target.value && event.target.value !== "Guests...") {
            bookNowButton.removeAttribute("disabled");
        } else {
            bookNowButton.setAttribute("disabled", true);
        }
    }

    // Init
    function init() {
        if (document.getElementById("event-booking-bar-container") === null) {
            return;
        }
    
        window.addEventListener("scroll", onWindowScrolled);
        onWindowScrolled();

        model.init();
        model.getStep("where").get();
        updateBarState();
    };


    function onWindowScrolled() {
        const container = document.getElementById("event-booking-bar-container");
        const content = container.nextElementSibling;
        const isPositionFixed = window.getComputedStyle(container).position === "fixed";

        // Get the current break points
        const width = window.innerWidth;
        let heightCheck = 600;
        let top = "100px";
        let marginTop = "75px";
        
        if (width <= 1024) {
            top = "100px";
            heightCheck = 450;
            marginTop = "235px";
        }
        if (width <= 768) {
            top = "50px";
            heightCheck = 450;
            marginTop = "235px";
        }
        if (width <= 639) {
            top = "50px";
            heightCheck = 300;
            marginTop = "104px";
        }

        if (window.scrollY > heightCheck && !isPositionFixed) {
            container.style.position = "fixed";
            container.style.top = top;
            content.style.marginTop = marginTop;
        }
        if (window.scrollY < heightCheck && isPositionFixed) {
            container.style.position = "static";
            container.style.top = "0";
            content.style.marginTop = "50px;";
        }
    }

    document.addEventListener("DOMContentLoaded", init);
})();